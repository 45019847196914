import $ from "jquery";
window.$ = window.jQuery = $;
import "./scss/career.scss";

function files(){
    let fileFields = document.querySelectorAll('.form__file');
    if (fileFields){
        fileFields.forEach((field) =>{
            let fileInput = field.querySelector('input[type="file"]');
            let fileText = field.querySelector('.form__file-text');
            if (fileInput && fileText){
                fileInput.addEventListener('change', (e) => {
                    fileText.innerHTML = fileInput.files[0].name;
                });
            }
        });
    }
}

$(document).ready(()=>{
    $('.job__heading').each(function() {
        let parent = $(this).parent();
        let target = $(this).next();
        $(this).click(function () {  
            target.slideToggle();
            parent.toggleClass('active');
            $('.job__content').not(target).slideUp();
            $('.job').not(parent).removeClass('active');
        })
    });

    $('input[name="stanowisko"]').each(function () {
        let parent = $(this).parents('.job');
        let title = parent.find('.job__title');
        $(this).val(title.html());
    });

    files();
});